import React from 'react'
import Header from '../components/home/Header'
import About from '../components/home/About'
import OurServices from '../components/home/Services'
import Clients from '../components/home/Clients'
import Testimonials from '../components/home/Testimonials'
import { Helmet } from 'react-helmet'

function Home() {
  return (
    <>
    <Helmet>
      <title>
        Legal Sages || Navigating Legal Empowerment!
      </title>
    </Helmet>
      <Header />
      <About />
      <OurServices />
      <Clients />
      <Testimonials />
    </>
  )
}

export default Home