import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './style.scss';


const Scam = 'https://www.middlemarketcenter.org/Media/Expert%20Perspectives%20Images/businessmeetinginanoffic_202974.jpg'

const About = () => {
  return (
    <Box className="about-section">
      <Box className="about-content">
        <Typography variant="h4" component="h2" className="about-title">
          About Legal Sages
        </Typography>
        <Typography variant="body1" className="about-description">
          Legal Sages, powered by Apex Legal, is committed to revolutionizing the legal field by
          making top-tier services both affordable and client-friendly. With a multidisciplinary
          approach, we address the challenges of modern businesses, including technology-driven
          disruptions, regulatory changes, and competitive pressures.
        </Typography>
        <Typography variant="body1" className="about-description">
          Our seasoned professionals offer expertise in a variety of areas, including Digital Scam Resolution & Data Privacy
          ,Virtual General Counsel, Business Setup, Registration & Licencing, Fund Raising, Compliance, Audit and Due Diligence, Valuation and more. We work closely with our clients to provide incisive and strategic
          solutions tailored to their specific needs.
        </Typography>
        <Button variant="contained" href='/about' color="primary" className="learn-more-btn">
          Learn More
        </Button>
      </Box>
      <Box className="about-image">
        <img
          src={Scam}
          alt="Legal Sages Team"
          className="image"
        />
      </Box>
    </Box>
  );
};

export default About;
