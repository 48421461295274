import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './style.scss'; // Import the SCSS file for custom styling


const img1 = 'https://static.wixstatic.com/media/508759_b8d332384b704ee19eddf0bc00d3b8aa~mv2.jpg/v1/fill/w_792,h_521,al_c,lg_1,q_85/508759_b8d332384b704ee19eddf0bc00d3b8aa~mv2.jpg'

const VisionSection = () => {
    return (
        <Box className="vision-section" sx={{ padding: '40px 0' }}>
            <Grid container spacing={4} alignItems="center">
                {/* Right Image Section */}
                <Grid item xs={12} md={6}>
                    <Box className="vision-image">
                        <image src={img1} width={100} />
                    </Box>
                </Grid>

                {/* Left Content: Heading, Title, and Description */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" className="vision-title" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        Legal Sages powered by Apex Legal
                    </Typography>

                    <Typography variant="h6" className="vision-heading" sx={{ fontWeight: 'normal', marginBottom: 2 }}>
                        Aiming to Revolutionize Legal Practice with Affordable and Client-Friendly Solutions
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        Legal Sages aims to revolutionize legal practice by making it both affordable and client-friendly. Every practice area is supported by industry-focused teams, dedicated to providing incisive and strategic advice.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        We endeavor to remain innovative and methodical, delivering value-added services to our clients. We bring together all resources to maximize the impact of every case, ensuring the highest standard of service. Our approach is defined by our ability to respond swiftly to client needs and provide the best legal solutions.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        We take the time to understand our clients' needs, businesses, and the legal implications they face across various sectors and jurisdictions—both domestically and internationally. Today, Legal Sages is recognized for its ability to identify and mitigate risks for clients, offering top-tier legal advice.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        Once we begin our commercial relationship with a client, we ensure excellence in every aspect, maintaining the highest standard of practices. We welcome the opportunity to hear from you and continuously improve in our pursuit of excellence.
                    </Typography>

                    <Typography variant="h6" className="vision-subheading" sx={{ fontWeight: 'bold', marginTop: 4 }}>
                        Legal Sages's Multi-Disciplinary Approach
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        Legal Sages is a multi-disciplinary Corporate & Commercial boutique law firm, focusing on business-related legal areas and providing a comprehensive range of legal services and solutions to domestic and international clients. Established by Mr. Umesh Kumar, an esteemed Corporate Lawyer, Registered Valuer – SFA, and Insolvency Professional, the firm brings together a team of diverse professionals from lawyers to chartered accountants and company secretaries to provide seamless, cross-practice expertise.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        Serving a large clientele, including businesses, leading Indian and international companies, we offer integrated litigation and regulatory services. Our expertise spans Digital Scam Resolution, Business Setup, Virtual General Counsel, M&A, Corporate Restructuring, Insolvency & Bankruptcy Laws, Data Privacy & Cyber Laws, Litigation & Dispute Resolution, Governance, Risk & Compliance, Registrations & Licensing, Audits & Due Diligences, Valuation, Listing Comliance, Foreign Exchange Transactions and more.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        We understand the evolving challenges faced by clients in today's fast-paced business environment, driven by technological advancements, shifting regulations, and competitive pressures. Our commitment is to provide a diverse range of legal services, helping clients navigate these challenges effectively.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        Our founder was proudly recognized with The Great Indian Legal Eagle Award by the Transformance Forum in 2024, a prestigious accolade that celebrates exceptional legal expertise and leadership. This recognition highlights his unwavering commitment to excellence and his significant contributions to the legal profession.
                    </Typography>

                    <Typography className="vision-description" sx={{ marginBottom: 2 }}>
                        At Apex Legal, we uphold core values that define who we are and what we stand for. These principles guide us through the ever-changing business landscape.
                    </Typography>

                </Grid>
            </Grid>
        </Box>
    );
};

export default VisionSection;
