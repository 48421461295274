import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, MenuItem, Button, useMediaQuery, useTheme } from '@mui/material';
import './style.scss';

const BookNow = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    service: '',
    message: '',
  });

  const services = [
    'Virtual General Counsel',
    'Valuation',
    'Digital Scam & Data Privacy',
    'Setting up a Business Entity',
    'Fund Raising',
    'Foreign Exchange Transactions',
    'Compliance, Audit and Due Diligence',
    'SME IPO Advisory',
    'Investor Relation',
    'Dispute Resolution',
    'Insolvency and Bankruptcy',
    'Virtual CFO',
    'Accounting & Taxation',
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // You can add form submission logic here
  };

  return (
    <div
      className="booknow-section-container"
    >
      <Grid
        container
        // spacing={isSmallScreen ? 2 : 4}
        className="book-now-grid"
        sx={{
          flexDirection: isSmallScreen ? 'column' : 'row',
        }}
      >
        {/* Left Section */}
        <Grid
          item
          xs={12}
          md={6}
          className="left-section"
          sx={{
            padding: isSmallScreen ? '1rem' : '5%',
            textAlign: isSmallScreen ? 'center' : 'left',
          }}
        >
          <div className="leftContent">
            <Typography
              variant={isSmallScreen ? 'h6' : isMediumScreen ? 'h4' : 'h3'}
              className="section-title"
              sx={{
                fontWeight: 'bold',
              }}
            >
              Book an Appointment
            </Typography>
            <Typography
              variant="body1"
              className="section-desc"
              sx={{
                fontSize: isSmallScreen ? '0.9rem' : '1rem',
                marginBottom: '1.5rem',
              }}
            >
              Schedule a consultation to explore tailored legal services that help protect your business, resolve disputes,
              and ensure compliance. Our team is ready to provide expert assistance to suit your specific legal needs.
            </Typography>
            <Box className="links">
              <a href="/services" className="link">
                Explore Our Services
              </a>
              <a href="/contact" className="link">
                Contact Us
              </a>
            </Box>
          </div>
        </Grid>

        {/* Right Section */}
        <Grid
          item
          xs={12}
          md={6}
          elevation={3}
          className="appointment-card"
          sx={{
            padding: isSmallScreen ? '1rem' : '2rem',
            backgroundColor: isSmallScreen ? '#f9f9f9' : '#ffffff',
            borderRadius: '12px',
          }}
        >
          <form onSubmit={handleSubmit} className="appointment-form">
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              label="Phone Number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              type="tel"
              required
            />
            <TextField
              label="Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              type="email"
              required
            />
            <TextField
              select
              label="Select a Service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            >
              {services.map((service, index) => (
                <MenuItem key={index} value={service}>
                  {service}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
              sx={{
                width: isSmallScreen ? '100%' : 'auto',
                marginTop: '1rem',
              }}
            >
              Book Now
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default BookNow;
