import React from 'react'
import Hero from '../components/BookNow/Hero'
import BookNow from '../components/home/BookNow'

function Book() {
  return (
    <>
      <Hero />
      <BookNow />
    </>
  )
}

export default Book