import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link as MuiLink,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/Logo.png'
import './Style.scss'


const Layout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navLinks = [
    { text: 'Home', path: '/' },
    { text: 'About', path: '/about' },
    { text: 'Services', path: '/services' },
    { text: 'Contact', path: '/contact' },
  ];

  return (
    <>
      {/* Header Navigation */}
      <AppBar position="static" className="layout-appbar" sx={{ height: '100px', bgcolor: "#212121" }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo */}
          <a href='/'>
            <img src={logo} style={{ height: '100px', width: 'auto' }} alt="Logo" />
          </a>

          {/* Desktop Navigation Links */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 2,
            }}
          >
            {navLinks.map((link) => (
              <MuiLink
                key={link.text}
                component={Link}
                to={link.path}
                color="inherit"
                underline="none"
                sx={{
                  marginTop: "10px",
                }}
              >
                {link.text}
              </MuiLink>
            ))}
            {/* <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="https://forms.gle/4pMPjD2wMUQF79cq6"
              sx={{
                padding: { xs: '5px 10px', sm: '6px 12px' },
                fontSize: { xs: '0.8rem', md: '1rem' },
              }}
              className="book-btn"
            >
              Book Now
            </Button> */}
          </Box>

          {/* Mobile Menu Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>


      {/* Mobile Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: 250,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2,
          }}
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton edge="end" color="inherit" onClick={toggleDrawer}>
              <CloseIcon />
            </IconButton>
          </Box>

          <List>
            {navLinks.map((link) => (
              <ListItem
                button
                key={link.text}
                component={Link}
                to={link.path}
                onClick={toggleDrawer}
              >
                <ListItemText primary={link.text} />
              </ListItem>
            ))}
            <Divider />
            {/* <ListItem
              button
              component={Link}
              to="https://forms.gle/4pMPjD2wMUQF79cq6"
              onClick={toggleDrawer}
              sx={{ marginTop: 2 }}
            >
              <Button variant="contained" color="secondary" fullWidth>
                Book
              </Button>
            </ListItem> */}
          </List>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ padding: { xs: 2, sm: 3 } }}>
        {children}
      </Box>

      {/* Footer Section */}
      <Box sx={{ backgroundColor: '#333', color: '#fff', padding: '20px 0' }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {/* Company Info */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Company Info
              </Typography>
              <Typography variant="body2">Legal Sages, powered by Apex Legal, is committed to revolutionizing the legal field by making top-tier services both affordable and client-friendly.</Typography>
            </Box>

            {/* Quick Links */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Quick Links
              </Typography>
              <MuiLink
                component={Link}
                to="/"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Home
              </MuiLink>
              <MuiLink
                component={Link}
                to="/about"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                About
              </MuiLink>
              <MuiLink
                component={Link}
                to="/services"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Services
              </MuiLink>
              <MuiLink
                component={Link}
                to="/contact"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Contact
              </MuiLink>
            </Box>

            {/* Services */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Our Services
              </Typography>
              <MuiLink
                component={Link}
                to="/virtual-general-counsel"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Virtual General Counsel
              </MuiLink>
              <MuiLink
                component={Link}
                to="/digital-scam"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Digital Scam Resolution
              </MuiLink>
              <MuiLink
                component={Link}
                to="/business-setup"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Business Setup
              </MuiLink>
              <MuiLink
                component={Link}
                to="/valuation"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Valuation
              </MuiLink>
            </Box>
            {/* Legal */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Legal
              </Typography>
              <MuiLink
                component={Link}
                to="/terms-conditions"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Terms and Conditions
              </MuiLink>
              <MuiLink
                component={Link}
                to="/privacy-policy"
                color="inherit"
                underline="none"
                display="block"
                sx={{ marginBottom: 1 }}
              >
                Privacy Policy
              </MuiLink>
            </Box>
          </Box>

          {/* Footer Bottom */}
          <Box sx={{ marginTop: 4, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', gap: 2 }}>
            <Typography variant="body2" sx={{ color: '#ccc', textAlign: 'center' }}>
              © {new Date().getFullYear()} Legal Sages. All rights reserved.
            </Typography>
            <Typography variant="body2" sx={{ color: '#ccc', textAlign: 'center' }}>
              Developed with ❤️ by{' '}
              <a
                href="https://sparkhue.co"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#ccc', textDecoration: 'none' }}
              >
                SparkHue Co.
              </a>
            </Typography>
          </Box>
        </Container>
      </Box>

    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
