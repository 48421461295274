import React from 'react';
import { Typography, Box } from '@mui/material';
import './Style.scss';

const HeroSection = () => {
  return (
    <Box className="hero-section">
      <Box className="hero-content">
        <Typography variant="h2" className="hero-heading">
          Compliance, Audit, and Due Diligence
        </Typography>
        <Typography variant="h6" className="hero-subheading">
          Strengthen your compliance framework with expert insights and safeguard your business from potential risks.
        </Typography>
      </Box>
      <Box className="hero-image">
        <img src="https://www.jadeglobal.com/sites/default/files/2022-09/Ways-Life-Sciences-Companies-can-achieve-Continuous-Compliance-with-Managed-Services.jpg" alt="Compliance Hero" />
      </Box>
    </Box>
  );
};

export default HeroSection;
