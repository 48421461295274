import React from "react";
import { Box, Typography, List, ListItem, ListItemText, Grid } from "@mui/material";

const DescribedSection = () => {
  return (
    <Box sx={{ backgroundColor: "background.default", borderRadius: "12px" }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Section: Illustration */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src="https://thumb.ac-illust.com/fe/fe80f80a41895d2bf49b2fee38d663fc_t.jpeg"
              alt="Virtual General Counsel"
              style={{
                borderRadius: "12px",
                width: "100%",
                height: "auto",
                maxWidth: "350px",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>

        {/* Right Section: Content */}
        <Grid item xs={12} md={7}>
          <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: "1.5rem" }}>
            Why Choose Virtual General Counsel Services?
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
            High in-house legal costs can strain your budget. With Virtual General Counsel (VGC) services, you get expert
            legal advice and support tailored to your business needs at a fraction of the cost of a full-time legal
            team.
          </Typography>

          <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
            VGC services empower startups, SMEs, and fast-growing companies by providing:
          </Typography>

          <List>
            <ListItem>
              <ListItemText primary="On-demand, expert legal assistance for your specific business challenges." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Comprehensive compliance support to minimize risks and avoid penalties." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Strategic legal advice to drive business continuity and operational success." />
            </ListItem>
            <ListItem>
              <ListItemText primary="Cost-effective solutions customized for startups and fast-growing businesses." />
            </ListItem>
            <ListItem>
              <ListItemText primary="A flexible alternative to traditional in-house legal teams, reducing overheads." />
            </ListItem>
          </List>

          <Typography variant="body1" sx={{ marginTop: "1.5rem", marginBottom: "1rem" }}>
            This streamlined legal service ensures you stay compliant, protected, and focused on growing your business
            without legal worries.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold", color: "text.primary", marginBottom: "10%" }}>
            Ready to simplify your legal processes and empower your growth? Hire now for hassle-free legal solutions.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DescribedSection;
