import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import digitalScam from '../../assets/digital-scam.webp';

const Hero = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <Box sx={{ backgroundColor: "#f5f5f5", py: 8, px: 4 }}>
      <Grid container alignItems="center"
        sx={{
          margin: "0 auto",
          flexDirection: isSmallScreen ? "column-reserve" : "row",
        }}>
        {/* Left Section: Content */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom color="primary">
            Digital Scam Resolution & Data Privacy
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            "Worried about digital scams? Protect yourself with expert solutions and stay secure!"
          </Typography>
          <Typography variant="body1" paragraph>
            India's rapid digital transformation has brought unparalleled convenience, from online banking to e-commerce.
            However, it has also led to a surge in online scams. Cybercriminals have become increasingly sophisticated,
            using diverse tactics to target individuals across all demographics, including youth, seniors, and professionals.
          </Typography>
          <Typography variant="body1" paragraph>
            Are you facing digital scams or concerned about data privacy risks? Below are the most prevalent online scams.
            Avail of expert support to safeguard and protect yourself against these risks:
          </Typography>
        </Grid>

        {/* Right Section: Image */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={digitalScam}
            alt="Digital Security"
            sx={{
              width: "100%",
              maxHeight: "400px",
              borderRadius: 2,
              objectFit: "cover",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
