import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import './Style.scss'


const BusinessSetup = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundColor: "#f9fbfd",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          margin: "0 auto",
          flexDirection: isSmallScreen ? "column-reverse" : "row",
        }}
      >
        {/* Content Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "#1a73e8",
                fontWeight: "bold",
                // marginBottom: "1rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Setting up a Business Entity
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontStyle: "italic",
                color: "#555",
                // marginBottom: "1.5rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              "Ready to launch your business? Unlock expert solutions for a seamless, hassle-free journey from start to
              finish. We're here to guide you at every step!"
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#333", lineHeight: "1.8", marginBottom: "1.5rem" }}
            >
              Set up a hassle-free business entity with comprehensive, end-to-end solutions and expert mentorship to
              drive rapid and sustainable growth. Avail expert guidance to establish the following entities:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#1a73e8",
                fontWeight: "600",
                marginBottom: "1rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Business Entities We Help Establish:
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: isSmallScreen ? "20px" : "40px",
                color: "#555",
                // lineHeight: "1.8",
              }}
            >
              {[
                "Startup",
                "Stock Broking Entity in Gift IFSC SEZ Gandhi Nagar Gujarat - Deemed Foreign territory",
                "Fund Management entity in Gift IFSC SEZ Gandhi Nagar Gujarat - Deemed Foreign territory",
                "Stock Broking and Depository Participant - Non Gift IFSC SEZ territory",
                "Research Analyst",
                "Investment Adviser",
                "Non-Banking Financial Company",
                "Nidhi Company",
                "Merchant Banker",
                "Insurance Broking",
                "Valuation entity",
              ].map((entity, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  {entity}
                </li>
              ))}
            </ul>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <img
              src="https://cdn-aeflm.nitrocdn.com/NJGvfdNQTJKvYlKQUINUKLqSCjJVyDtD/assets/images/optimized/rev-e75ca90/intuitconsultancy.com/wp-content/uploads/2023/07/UK-and-Singapore-Tech-Ties-Strengthened-with-New-Data-and-AI-Agreements.jpg"
              alt="Business Setup"
              style={{
                width: "100%",
                maxWidth: "95%",
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessSetup;
