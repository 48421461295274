import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './style.scss';

const Hero = () => {
  return (
    <section id="book-now-hero" className="book-now-hero">
      {/* Hero Section */}
      <Box className="hero-content">
        <Typography variant="h2" className="hero-heading">
          Book Your Appointment Today!
        </Typography>
        <Typography variant="h5" className="hero-subheading">
          Get expert legal advice and consultation for your needs. Our professionals are ready to assist you.
        </Typography>
      </Box>
    </section>
  );
};

export default Hero;
