import React from "react";
import { Typography, Box, Container } from "@mui/material";
import Hero from "./Hero";
import HelpSection from "./Help";
// import BookNow from "../home/BookNow";

const DigitalScamContent = () => {
  return (
    <>
    <Hero />
    <Container>
      <Box my={4}>
        {/* Section for Each Scam */}
        {[
          {
            title: "Digital Arrest Scams",
            content: `
              Victims receive a phone call, email, or message claiming they are under investigation for illegal activities, 
              such as identity theft or money laundering. Scammers threaten with arrest or legal consequences unless 
              immediate action is taken, often coercing individuals to transfer large sums of money.`,
          },
          {
            title: "Job Scams",
            content: `
              Scammers post fake job listings on legitimate job portals or social media. Victims are asked to pay application fees, 
              training charges, or background check costs. Red flags include offers that seem too good to be true or require upfront payments.`,
          },
          {
            title: "Phone Scams",
            content: `
              Victims receive calls or messages claiming they’ve won a prize or face service issues. Scammers create urgency and demand 
              personal information, like OTPs, leading to loss of sensitive data.`,
          },
          {
            title: "Loan/Card Scams",
            content: `
              Fraudsters promise low-interest loans or attractive cards via ads or social media, coercing individuals into paying upfront 
              fees or sharing sensitive data.`,
          },
          {
            title: "Investment Scams",
            content: `
              Scammers use Ponzi or pyramid schemes disguised as high-yield investments, exploiting individuals’ lack of financial 
              literacy and promising unrealistic returns.`,
          },
          {
            title: "Phishing Scams",
            content: `
              Scammers use legitimate-looking emails or messages to prompt victims into clicking fake links. These scams target login 
              credentials or personal data.`,
          },
          {
            title: "Lottery and Prize Scams",
            content: `
              Victims are notified of winning a lottery they never entered. Scammers demand payment of processing fees or taxes while 
              exploiting hope and greed.`,
          },
          {
            title: "Emotional Manipulation Scams",
            content: `
              Scammers create fake profiles on dating apps to build trust, then fabricate crises (e.g., medical emergencies) to solicit 
              money, often in cryptocurrency.`,
          },
          {
            title: "Tech Support Scams",
            content: `
              Victims receive unsolicited calls claiming their device has a virus, requesting remote access. Scammers install malware or 
              steal information, pressuring payment for unnecessary services.`,
          },
          {
            title: "Cash-on-Delivery (CoD) Scams",
            content: `
              Fake online stores deliver counterfeit or irrelevant products for CoD orders, causing financial loss and eroding trust in 
              genuine platforms.`,
          },
          {
            title: "Fake Charity Appeal Scams",
            content: `
              Scammers set up fake profiles or websites soliciting donations for non-existent causes, exploiting compassion with 
              compelling stories and imagery.`,
          },
          {
            title: "Mistaken Money Transfer Scams",
            content: `
              Scammers claim money was mistakenly sent to the victim’s account, providing fake bank statements to create urgency for 
              repayment.`,
          },
          {
            title: "Parcel Scams (FedEx/DHL)",
            content: `
              Victims receive threats that their parcel contains illegal items, such as drugs, with demands for fines to avoid legal 
              action.`,
          },
          {
            title: "Crime Related to Women/Children",
            content: `
              Includes online distribution of Child Pornography (CP), Child Sexual Abuse Material (CSAM), or explicit content like 
              Rape/Gang Rape (CP/RGR), exploiting victims through blackmail or shaming.`,
          },
        ].map((scam, index) => (
          <Box key={index} my={3}>
            <Typography variant="h6" color="secondary">
              {scam.title}
            </Typography>
            <Typography variant="body2" paragraph>
              {scam.content}
            </Typography>
          </Box>
        ))}
      </Box>
    </Container>
    <HelpSection />
    {/* <BookNow /> */}
    </>
  );
};

export default DigitalScamContent;
