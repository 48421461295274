import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './style.scss'; // Import the SCSS file for custom styling

const MissionSection = () => {
  return (
    <Box className="mission-section">
      <Grid container spacing={4} alignItems="center">
        {/* Left Content: Heading, Title, and Description */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" className="mission-title">
            Our Mission
          </Typography>
          <Typography variant="h6" className="mission-heading">
            Providing Accessible and Effective Legal Services
          </Typography>
          <Typography className="mission-description">
            At Legal Sages, our mission is to bridge the gap between businesses and the legal system by providing
            accessible, expert legal advice. We strive to empower our clients with the knowledge and resources to
            navigate complex legal matters confidently.
          </Typography>
          <Typography className="mission-description">
            We believe in making legal services transparent, affordable, and impactful, allowing businesses and
            individuals to thrive in an ever-evolving legal landscape.
          </Typography>
          <Typography variant="h6" className="vision-subheading" sx={{ fontWeight: 'bold', marginTop: 4 }}>
            How We Work
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            We inter alia offer services for businesses, entrepreneurs, and public entities. Our approach fosters success by working in close collaboration with clients, maintaining personal commitment at every level, and delivering bold, practical solutions. We strive for seamless cooperation and transparent communication.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            We ensure that our clients receive not only what they need but also that little extra something that makes all the difference.
          </Typography>

          <Typography variant="h6" className="vision-subheading" sx={{ fontWeight: 'bold', marginTop: 4 }}>
            Our Standards
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Client Focused: Our clients are the driving force behind everything we do. We unite forces to achieve the highest possible impact and go the extra mile when necessary.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Integrity & Professionalism: We act with integrity towards our team and clients, fostering openness and transparency. Mutual trust and respect are key to our work culture.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Enhancing Knowledge: Knowledge is vital to our success. We prioritize learning and have built a rich mentoring environment with access to exclusive resources.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Commitment: We are committed to environmental sustainability and social responsibility, ensuring a healthy and safe working environment for all employees and promoting equality and zero tolerance to discrimination.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Transparency: We maintain transparency in all our interactions, offering direct access to our highly experienced team, ensuring a smooth and personalized decision-making process.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            • Solution-based Approach: We are hands-on, thorough, and approachable, delivering creative and practical legal solutions, while maintaining an open, dependable relationship with our clients.
          </Typography>

          <Typography className="vision-description" sx={{ marginBottom: 2 }}>
            We're offering practical and comprehensive legal solutions tailored to client needs.
          </Typography>
        </Grid>

        {/* Right Image Section */}
        <Grid item xs={12} md={6}>
          <Box className="mission-image" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MissionSection;
