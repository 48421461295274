import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './style.scss';

const HeroSection = () => {
    return (
        <Box className="hero-section">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" className="hero-heading">
                        About Legal Sages
                    </Typography>
                    <Typography variant="h6" className="hero-description">
                        Legal Sages is powered by Apex Legal, a trusted name in the legal industry offering cutting-edge services
                        for individuals and businesses.
                    </Typography>
                </Grid>
                
                {/* Left Image Section */}
                <Grid item xs={12} md={6}>
                    <Box className="hero-image" />
                </Grid>

            </Grid>
        </Box>
    );
};

export default HeroSection;
