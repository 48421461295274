import React from "react";
import { Box } from "@mui/material";
import HeroSection from "./Hero";
import DescribedSection from "./DescribedSection";
// import BookNow from "../home/BookNow";

const VirtualGeneralCounsel = () => {
  return (
    <Box sx={{ padding: "2rem" }}>
      <HeroSection />
      <DescribedSection />
      {/* <BookNow /> */}
    </Box>
  );
};

export default VirtualGeneralCounsel;
