import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
// import BookNow from "../home/BookNow";

const Registration = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundColor: "#f9fbfd",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px"
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <img
              src="https://leadgenapp.io/wp-content/uploads/2023/01/shutterstock_761462098-scaled-1-1024x645.jpg"
              alt="Registration Process"
              style={{
                width: "100%",
                maxWidth: "95%",
                borderRadius: "12px",
                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
              }}
            />
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "#1a73e8",
                fontWeight: "bold",
                marginBottom: "1rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Simplify Your Registration Process
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontStyle: "italic",
                color: "#555",
                marginBottom: "1.5rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              "Get your business registered seamlessly with our expert guidance and hassle-free process."
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#333", lineHeight: "1.8", marginBottom: "1.5rem" }}
            >
              We provide step-by-step assistance to ensure a smooth registration experience for your business. Our
              services cover all essential aspects to help you get started quickly and efficiently.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#1a73e8",
                fontWeight: "600",
                marginBottom: "1rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Key Registration Services We Offer:
            </Typography>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: isSmallScreen ? "20px" : "40px",
                color: "#555",
                lineHeight: "1.8",
              }}
            >
              {[
                "Company Incorporation (Private Limited, LLP, etc.)",
                "GST Registration",
                "Trademark Registration",
                "FSSAI License Registration",
                "MSME Registration",
                "Import Export Code (IEC) Registration",
                "Shop and Establishment Registration",
                "Professional Tax Registration",
              ].map((service, index) => (
                <li key={index} style={{ marginBottom: "8px" }}>
                  {service}
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
        
      </Grid>
      {/* <BookNow /> */}
    </Box>
  );
};

export default Registration;
