import React from "react";
import { Box } from "@mui/material";
import BusinessSetup from "./BusinessSetup";
import Registration from "./Registration";

const SBRL = () => {
  return (
    <Box sx={{ backgroundColor: "#f9f9f9", py: 6, px: 4 }}>
      <BusinessSetup />
      <Registration />
    </Box>
  );
};

export default SBRL;
