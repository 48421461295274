import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import './Style.scss';

const AboutCompliance = () => {
  return (
    <Box>
      <Container className="compliance-container">
        <Typography variant="h4" className="section-heading">
          Compliance, Audit, and Due Diligence Services
        </Typography>
        <Typography variant="body1" className="section-description">
          Compliance, audit, and due diligence services are essential for ensuring regulatory adherence, financial accuracy, and informed decision-making. Compliance ensures a company meets legal and industry standards, audits provide transparency and confidence in financial statements, and due diligence helps assess risks and opportunities before major business decisions.
        </Typography>
        <Typography variant="body1" className="section-description">
          These services mitigate risks, maintain financial integrity, and support smart business strategies. Get in touch with us to avail expert solutions for compliance, audit, and due diligence to safeguard your business operations and ensure long-term success.
        </Typography>
      </Container>
    </Box>
  );
};

export default AboutCompliance;
