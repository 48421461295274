import React from 'react'
import FundHero from '../components/FundRaising/Hero'
import FundRaisingSection from '../components/FundRaising/FundSection'

function FundRaising() {
  return (
    <>
        <FundHero />
        <FundRaisingSection />
    </>
  )
}

export default FundRaising