import React from "react";
import { Box, Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
// import BookNow from "../home/BookNow";

const Valuation = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundColor: "#f9fbfd",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          margin: "0 auto",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >

        {/* Illustration Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            <img
              src="https://cdn.corporatefinanceinstitute.com/assets/valuation.jpeg"
              alt="Valuation Illustration"
              style={{
                width: "100%",
                maxWidth: "95%",
                borderRadius: "12px",
                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
              }}
            />
          </Box>
        </Grid>

        
        {/* Content Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: "#1a73e8",
                fontWeight: "bold",
                marginBottom: "1rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              Valuation
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontStyle: "italic",
                color: "#555",
                marginBottom: "1.5rem",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              "Unsure about your business valuation? Let our experts deliver accurate assessments to ensure you get the
              right value every time."
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#333", lineHeight: "1.8", marginBottom: "1.5rem" }}
            >
              Proper valuation across diverse asset classes like Securities & Financial Assets, Land & Building, and
              Plant and Machinery is a cornerstone of strategic business planning and growth. Accurate valuation not
              only reflects a company's true worth but also plays a crucial role in raising capital, attracting
              investors, and preparing for future expansion or exit strategies.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#333", lineHeight: "1.8", marginBottom: "1.5rem" }}
            >
              Business valuation, especially for startups and growing companies, is critical in positioning the company
              for scalable growth, mergers, or acquisitions. Leveraging expert valuation services provides the insights
              needed to navigate financial decisions with confidence, ensuring every asset contributes to the business’s
              full potential and long-term resilience.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ color: "#333", lineHeight: "1.8" }}
            >
              Avail specialized valuation solutions to drive informed decisions, facilitate sustainable growth, and
              secure a competitive edge in today’s dynamic market.
            </Typography>
          </Box>
        </Grid>

      </Grid>
      {/* <BookNow /> */}
    </Box>
  );
};

export default Valuation;
