import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation
import VGC from '../../assets/Virtual.png';
import compliance from '../../assets/compliance.jpeg';
import digitalScam from '../../assets/digital-scam.webp';
// import DisputeResolution from '../../assets/Dispute-Resolution.jpg';
// import foreignExchange from '../../assets/foreign-exchange.jpg';
import Fraudincrease from '../../assets/fundraising.webp';
// import InsolvencyBankruptcy from '../../assets/Insolvency-Bankruptcy.jpeg';
// import InvestorRelations from '../../assets/Investor-Relations.webp';
import settingUp from '../../assets/settings-up.jpeg';
// import SMEIPO from '../../assets/sme-ipo.jpg';
import Valuation from '../../assets/valuation.webp';
// import SocialWelfare from '../../assets/Social-Welfare.jpeg';
// import VirtualCFO from '../../assets/Virtual-CFO.jpeg';

import './style.scss';

const services = [
    {
        title: 'Virtual General Counsel',
        image: VGC,
        description: 'Expert legal support tailored to your business needs, providing strategic advice and handling legal complexities so you can focus on growth.',
        link: '/virtual-general-counsel',
    },
    {
        title: 'Digital Scam Resolution & Data Privacy',
        image: digitalScam,
        description: 'Protect your business and clients from digital scams with robust data privacy solutions, ensuring compliance with regulations and safeguarding sensitive information.',
        link: '/digital-scam',
    },
    {
        title: 'Business Setup, Registration & Licencing',
        image: settingUp,
        description: 'Effortless solutions for starting your business, from legal registrations to operational structuring, to help you establish a strong foundation.',
        link: '/business-setup',
    },
    {
        title: 'Fund Raising',
        image: Fraudincrease,
        description: 'Strategic guidance for securing funds, connecting you with investors and helping craft compelling proposals to drive your business forward.',
        link: '/fund-raising',
    },
    // {
    //     title: 'Foreign Exchange Transactions',
    //     image: foreignExchange,
    //     description: 'Expert handling of cross-border financial transactions, ensuring smooth and compliant foreign currency exchanges for your business operations.',
    //     link: '/foreign-exchange',
    // },
    {
        title: 'Compliance, Audit and Due Diligence',
        image: compliance,
        description: 'Thorough oversight to ensure your business complies with regulations, backed by detailed audits and due diligence for secure operations.',
        link: '/compliance',
    },
    // {
    //     title: 'SME IPO Advisory',
    //     image: SMEIPO,
    //     description: 'Customized solutions for small and medium enterprises, guiding you through the IPO process to unlock new growth opportunities in the capital market.',
    //     link: '/sme-ipo',
    // },
    // {
    //     title: 'Investor Relation',
    //     image: InvestorRelations,
    //     description: 'Bridge the gap between your company and its investors with tailored communication strategies to build trust and maintain transparency.',
    //     link: '/investor-relation',
    // },
    // {
    //     title: 'Dispute Resolution',
    //     image: DisputeResolution,
    //     description: 'Effective solutions for resolving legal disputes, employing negotiation, mediation, and arbitration to reach amicable agreements.',
    //     link: '/dispute-resolution',
    // },
    // {
    //     title: 'Insolvency and Bankruptcy',
    //     image: InsolvencyBankruptcy,
    //     description: 'Professional guidance to navigate financial distress, focusing on restructuring, asset recovery, and creditor negotiation.',
    //     link: '/insolvency-bankruptcy',
    // },
    // {
    //     title: 'Social Welfare',
    //     image: SocialWelfare,
    //     description: 'Comprehensive financial management services, including budgeting, financial planning, and reporting, to keep your business financially sound.',
    //     link: '/social-impact-assessor',
    // },
    {
        title: 'Valuation',
        image: Valuation,
        description: 'Comprehensive business valuation services to assess your company’s worth, helping you make informed decisions on investments, mergers, and acquisitions.',
        link: '/valuation',
    },
];

const OurServices = () => {
    return (
        <Box className="our-services-section" py={5}>
            <Typography variant="h4" component="h2" className="section-title" gutterBottom>
                Our Services
            </Typography>
            <Grid container spacing={3}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card className="service-card">
                            <CardMedia
                                component="img"
                                height="150"
                                image={service.image}
                                alt={service.title}
                                className="service-image"
                            />
                            <CardContent>
                                <Typography variant="h6" component="h3" className="service-title">
                                    {service.title}
                                </Typography>
                                <Typography variant="body2" className="service-description">
                                    {service.description}
                                </Typography>
                                <Link to={service.link} style={{ textDecoration: 'none' }}>
                                    <Button variant="outlined" color="primary" className="explore-btn">
                                        Explore More
                                    </Button>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default OurServices;
