import React from 'react'
import HeroSection from '../components/Compliance/HeroSection'
import AboutCompliance from '../components/Compliance/AboutSection'
import Helmet from 'react-helmet'


function Compliance() {
  return (
    <>
        <Helmet>
            <title>
            Compliance, Audit, and Due Diligence | Legal Sages
            </title>
        </Helmet>
        <HeroSection />
        <AboutCompliance />
    </>
  )
}

export default Compliance