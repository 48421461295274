import React from 'react'
import Hero from '../components/Contact/Hero'
// import ContactForm from '../components/Contact/ContactForm'
import ContactSection from '../components/Contact/ContactSection'

function Contact() {
  return (
    <>
      <Hero />
      {/* <ContactForm /> */}
      <ContactSection />
    </>
  )
}

export default Contact