import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import './style.scss'; // Import the SCSS file for custom styling

const AboutSection = () => {
    return (
        <Box className="about-section-page">
            <Grid container spacing={4} alignItems="center">


                {/* Right Image Section */}
                <Grid item xs={12} md={6}>
                    <Box className="about-image" />
                </Grid>

                {/* Left Content: Heading, Title, and Description */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" className="about-title">
                        About Us
                    </Typography>
                    <Typography variant="h6" className="about-heading">
                        Leading the Way in Accessible Legal Services
                    </Typography>
                    <Typography className="about-description">
                        Legal Sages was founded with the vision of simplifying legal processes and making expert legal advice accessible to all. We are committed to providing businesses and individuals with the best legal guidance, ensuring their success and compliance in the ever-evolving legal landscape.
                    </Typography>
                    <Typography className="about-description">
                        Our team of experienced legal professionals offers a wide range of services, from business formation and compliance to dispute resolution and data privacy. With a client-first approach, we tailor our services to meet the unique needs of each client, delivering effective solutions in a timely manner.
                    </Typography>
                    <Typography className="about-description">
                        We pride ourselves on our commitment to integrity, transparency, and professionalism. Our clients trust us to provide the highest level of legal expertise, and we are proud to be their trusted legal partner.
                    </Typography>

                    <Typography variant="h6" className="about-subheading">
                        Our Story
                    </Typography>
                    <Typography className="about-description">
                        Founded in 2010, Legal Sages has grown from a small firm to a trusted name in the legal services industry. Over the years, we’ve helped hundreds of businesses and individuals navigate complex legal challenges. Our dedicated team of professionals is passionate about helping clients achieve their goals and safeguard their interests.
                    </Typography>

                    <Typography variant="h6" className="about-subheading">
                        Why Choose Us?
                    </Typography>
                    <Typography className="about-description">
                        At Legal Sages, we believe that legal services should be accessible, affordable, and efficient. Our clients choose us because of our commitment to delivering personalized solutions, clear communication, and timely responses. We understand that every legal situation is unique, and we take the time to ensure that our clients receive the best possible advice.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AboutSection;
