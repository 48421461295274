import React from 'react'
import HeroSection from '../components/about/Hero'
import AboutSection from '../components/about/About'
import MissionSection from '../components/about/Mission'
import VisionSection from '../components/about/Vision'
import { Helmet } from 'react-helmet'

function About() {
  return (
    <>
    <Helmet>
      <title>
        About || Legal Sages || Navigating Legal Empowerment!
      </title>
    </Helmet>
      <HeroSection />
      <AboutSection />
      <MissionSection />
      <VisionSection />
    </>
  )
}

export default About