import React from 'react';
import { Box, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import pay from '../../assets/QR/donate.jpeg';
import './style.scss';

const ContactSection = () => {
  return (
    <Box className="contact-section" sx={{ py: 5, px: 3, backgroundColor: "#f5f5f5" }}>
      {/* Section Heading */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333' }}>
          Get in Touch
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, color: '#555' }}>
          We’re here to assist you! Reach out to us via call, email, or pay using the QR code below.
        </Typography>
      </Box>

      {/* QR Code Section */}
      <Box sx={{ textAlign: 'center', mb: 5 }}>
        <img 
          src={pay} 
          alt="Legal Sages Pay" 
          style={{
            width: '150px',
            height: '150px',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
          }} 
        />
        <Typography variant="body2" sx={{ mt: 2, color: '#777' }}>
          Scan the QR code to pay.
        </Typography>
      </Box>

      {/* Contact Cards */}
      <Grid container spacing={4} justifyContent="center">
        {/* Call Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            sx={{ 
              textAlign: 'center', 
              padding: '20px', 
              boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)', 
              borderRadius: '10px' 
            }}
          >
            <CardContent>
              <IconButton sx={{ color: '#1976d2', fontSize: '3rem' }}>
                <PhoneIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2 }}>
                Call Us
              </Typography>
              <Typography variant="body1" sx={{ mt: 1, color: '#666' }}>
                +91 9891520850
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Email Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card 
            sx={{ 
              textAlign: 'center', 
              padding: '20px', 
              boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)', 
              borderRadius: '10px' 
            }}
          >
            <CardContent>
              <IconButton sx={{ color: '#1976d2', fontSize: '3rem' }}>
                <EmailIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 2 }}>
                Email Us
              </Typography>
              <Typography variant="body1" sx={{ mt: 1, color: '#666' }}>
                hello@legalsages.com
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactSection;
