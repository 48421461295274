import React from "react";
import { Typography, Box } from "@mui/material";
import './style.scss';

const Hero = () => {
  return (
    <section className="contact-hero">
      <Box className="hero-content">
        <Typography variant="h1" className="hero-heading">
          Get in Touch
        </Typography>
        <Typography variant="h6" className="hero-description">
          We’d love to hear from you! Reach out for any queries or support.
        </Typography>
      </Box>
    </section>
  );
};

export default Hero;
