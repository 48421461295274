import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.scss';

const Header = () => {
  const slides = [
    {
      image: 'https://www.skillsyouneed.com/images/rhubarb/office-meeting.jpg',
      text: 'Your Virtual Legal Partner—Expert Counsel, Anytime, Anywhere!',
    },
    {
      image: 'https://outreachdigitalmarketing.com/wp-content/uploads/2021/08/Digital-Marketing-Scam.jpg',
      text: 'Stay Protected in the Digital Era—Scam Shield by LegalSages!',
    },
    {
      image: 'https://th.bing.com/th/id/R.e7ad3bc600c3b8404f453cbbfd9ff321?rik=P%2ffaDDpqMupTAQ&pid=ImgRaw&r=0',
      text: 'Ready to launch your business? Unlock expert solutions for a seamless, hassle-free journey from start to finish.',
    },
    {
      image: 'https://st4.depositphotos.com/12218988/22814/v/450/depositphotos_228146744-stock-illustration-blue-technology-abstract-background-white.jpg',
      text: 'Fuel Your Growth—Smart Fundraising Solutions for Visionary Entrepreneurs!',
    },
  ];
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  return (
    <Box className="carousel-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box key={index} className="carousel-slide">
            <img src={slide.image} alt={`Slide ${index + 1}`} className="carousel-image" />
            <Box className="text-overlay">
              <Typography variant='h2'>
                {slide.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Header;
