import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import './style.scss';

const FundRaisingSection = () => {
  return (
    <Box className="fundraising-section" sx={{ py: 5, px: 3, backgroundColor: "#f9f9f9" }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#333' }}>
          Understanding the Purpose of Fundraising
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, color: '#555' }}>
          Fundraising is more than just securing capital—it's about building a foundation for innovation, growth, and sustainability. Explore how the right funding can transform your business.
        </Typography>
      </Box>
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Start-Up Capital
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: '#666' }}>
                Launching a business begins with a solid financial base. Seed funding or angel investments provide the initial capital to bring your vision to life and establish a presence in the market.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Scaling Operations
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: '#666' }}>
                Growth often requires additional resources. Series A and B funding enable businesses to scale operations, enter new markets, and enhance product offerings to meet increasing demands.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Operational Continuity
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: '#666' }}>
                Maintaining daily operations and addressing unexpected challenges are critical. Debt funding, such as working capital or term loans, ensures businesses remain resilient and adaptable.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FundRaisingSection;
