import React from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";

const HeroSection = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column-reverse" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
      }}
      >
      {/* Text Content */}
      <Box sx={{ textAlign: isSmallScreen ? "center" : "left", }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            lineHeight: "1.2",
            marginBottom: "1rem",
            fontSize: isSmallScreen ? "2rem" : "2.5rem",
          }}
          >
          Virtual General Counsel
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: "1.5rem",
            fontSize: isSmallScreen ? "1rem" : "1.25rem",
            lineHeight: "1.6",
          }}
          >
          Expert legal support tailored to your business needs. Get strategic advice and handle legal complexities while
          focusing on growth.
        </Typography>
      </Box>

      {/* Image Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2rem",
          width: "100%",
        }}
      >
        <img
          src="https://www.tompkinswake.com/assets/Uploads/Virtual-GC4.png"
          alt="Virtual General Counsel"
          style={{
            borderRadius: "12px",
            width: "100%",
            height: "auto",
            objectFit: "cover",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        />
      </Box>
    </Box>
  );
};

export default HeroSection;
