import React from 'react'
import ServicesHeroSection from '../components/services/Hero'
import OurServices from '../components/home/Services'
import { Helmet } from 'react-helmet'

function Service() {
  return (
    <>
    <Helmet>
      <title>
        Services || Legal Sages || Navigating Legal Empowerment!
      </title>
    </Helmet>
      <ServicesHeroSection />
      <OurServices /> 
    </>
  )
}

export default Service