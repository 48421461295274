import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import { Link } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

const TermsConditions = () => {
    return (
        <>
        <Helmet>
            <title>
                Terms & Conditions || Legal Sages || Navigating Legal Empowerment!
            </title>
        </Helmet>
        <Container maxWidth="lg">
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Terms and Conditions
                </Typography>

                <Typography variant="h5" gutterBottom>
                    1. Introduction
                </Typography>
                <Typography variant="body1" gutterBottom>
                    i) Welcome to https://legalsages.com ‘LegalSages’, referred to as “Platform/Site” operated, owned and powered by Apex Legal “Firm”.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ii) These terms of use (“Terms and Conditions / Terms”) mandate the terms on which the users (“You” or “Your” or “User”) access, use, and register on the Platform i.e. “LegalSages”.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    iii) LegalSages shall also allow its associates, affiliates, group entities etc., together referred to as “Firm” or “We” or “Us” or LegalSages to use the Platform as per their requirements.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    iv) Please read the Terms and Privacy Policy carefully before using, registering on the Platform, or accessing any material or information through the Platform. It shall be your responsibility to check these Terms periodically for changes. Accordingly, please continue to review the Terms whenever accessing or using the Platform.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    v) Your use of the Platform as defined above constitutes your acceptance of the Terms and the agreement to be legally bound by the same. If you do not agree to the Terms in their entirety, please do not use or access the Platform. The Firm through this Platform, inter alia, provides various legal and social welfare services with the help of technology and artificial intelligence to its users, affiliates, group entities, and associates.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    vi) We reserve the right, at any time, at our sole discretion, to change or otherwise modify the Terms of Use without any prior notice, and your continued access or use of this platform signifies your acceptance of the updated or modified Terms of Use.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    2. Definitions
                </Typography>
                <Typography variant="body1" gutterBottom>
                    i) “Firm” shall mean Apex Legal.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    ii) “Services” shall mean the legal services offered and provided by the Firm through the Platform and shall also include the services offered and provided by group entities, affiliates, associates, and/or third parties to the users on the Platform.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    3. Use and Access to Platform/Site
                </Typography>

                <Typography variant="body1" paragraph>
                    i. Subject to compliance with the terms and conditions, The Firm hereby grants you a non-exclusive, limited privilege to access and use this Platform. You agree to use the Platform only: (a) for purposes that are permitted by the Terms; and (b) in accordance with any applicable law, regulation or generally accepted practices or guidelines.
                </Typography>

                <Typography variant="body1" paragraph>
                    ii. You agree that You shall not copy, reproduce, sell, redistribute, publish, enter into a database, display, perform, modify, alter, transmit, license, create derivatives from, transfer or in any way exploit any part of any information, content, materials, services available from or through the Platform, except that You may download the Platform for Your own personal, internal use and non-commercial use.
                </Typography>

                <Typography variant="body1" paragraph>
                    iii. You agree that you will not use the Platform in any manner or engage in any activity that may damage, disable or impair or adversely affect the use of the Platform or interfere with any other users’ use, legal rights, or enjoyment of the Platform. Further, you agree not to remove any text, copyright or other proprietary notices contained in the content downloaded from the Platform.
                </Typography>

                <Typography variant="body1" paragraph>
                    iv. The Firm grants you a non-exclusive and limited privilege to access and make personal use of the platform and the Service. This privilege does not cover any downloading or copying of any kind of information or data to the advantage of another individual, vendor or any other third party; caching, unauthorized hypertext links to the platform and the framing of any Content available through the platform uploading, posting, or transmitting any content that you do not have a right to make available (such as the intellectual property of another party); uploading, posting, or transmitting any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or device or hardware or telecommunications equipment; any action that imposes or may impose (at the sole discretion of the Firm) an unreasonable or disproportionately large load on the infrastructure of the Firm; or any use of data mining, robots, or similar data gathering and extraction tools. You may not bypass any measures used by the Firm to prevent or restrict access to the platform. Any unauthorized use by you shall terminate the permission or license granted to you by the Firm.
                </Typography>

                <Typography variant="body1" paragraph>
                    v. By using the Platform, you agree not to:
                    <ul>
                        <li>a) Use this platform or its contents for any commercial purpose;</li>
                        <li>b) Make any speculative, false, or fraudulent transaction or any transaction in anticipation of demand;</li>
                        <li>c) Access, monitor or copy any content or information of this platform using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;</li>
                        <li>d) Violate the restrictions in any robot exclusion headers on this platform or bypass or circumvent other measures employed to prevent or limit access to this Site;</li>
                        <li>e) Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
                        <li>f) Deep-link to any portion of this platform for any purpose without our express written permission;</li>
                        <li>g) “Frame”, “mirror” or otherwise incorporate any part of this platform into any other website/mobile app without our prior written authorization.</li>
                    </ul>
                </Typography>

                <Typography variant="body1" paragraph>
                    vi. Further, you undertake not to:
                    <ul>
                        <li>a) defame, abuse, harass, threaten or otherwise violate the legal rights of others;</li>
                        <li>b) publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</li>
                        <li>c) copy, republish, post, display, translate, transmit, reproduce or distribute any content on the Platform through any medium without obtaining the necessary authorization from the Firm;</li>
                        <li>d) conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
                        <li>e) upload or distribute files that contain software or other material protected by applicable intellectual property laws unless You own or control the rights thereto or have received all necessary consents;</li>
                        <li>f) upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Platform or another’s computer;</li>
                        <li>g) engage in any activity that interferes with or disrupts access to the Platform;</li>
                        <li>h) attempt to gain unauthorized access to any portion or feature of the Platform, any other systems or networks connected to the Platform, to any of the Firm’s server, or through the Platform, by hacking, password mining or any other illegitimate means;</li>
                        <li>i) probe, scan or test the vulnerability of the Platform or any network connected to the Platform;</li>
                        <li>j) disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers or networks connected to or accessible through the Platform;</li>
                        <li>k) collect or store data about other Users in connection with the prohibited conduct and activities;</li>
                        <li>l) use any device or software to interfere or attempt to interfere with the proper working of the Platform;</li>
                        <li>m) use the Platform or any material or content for any unlawful purpose;</li>
                        <li>n) falsify or delete any author attributions, legal or other proper notices or proprietary designations;</li>
                        <li>o) violate any applicable laws or regulations;</li>
                        <li>p) reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform.</li>
                        <li>q) Shall not use Firm’s and/or its affiliates, group entities and associates registration details to induce public to conduct business activities with you.</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    4. Your Account
                </Typography>

                <Typography variant="body1" paragraph>
                    i) You must provide true, accurate, current and complete information about yourself as prompted by the platform. If you provide any information that is false, inaccurate, obsolete or incomplete (or becomes so), or APEX LEGAL has reasonable grounds to suspect that such information is false, inaccurate, obsolete or incomplete, APEX LEGAL has the right to suspend or terminate your account and refuse any and all current or future use of the platform (or any portion thereof). It is your responsibility to maintain the confidentiality of your account and password, and for restricting access to your computer/device. You are also fully responsible for all activities that occur under your account or password. You agree to notify APEX LEGAL immediately of any unauthorized use of your account or any other breach of security. APEX LEGAL reserves the right to refuse service, terminate accounts or remove or edit content in its sole discretion. Notwithstanding the above, we retain the right at our sole discretion to deny access to anyone to the platform and the Services we offer, at any time and for any reason, including, but not limited to, for violation of the Terms of Use or Privacy Policy etc. Additional terms and conditions will apply to your availing / purchase of Services that you select. Please read them carefully. You agree to abide by the terms and conditions of purchase imposed by any service provider with whom you elect to deal, including, but not limited to, payment of all amounts when due and compliance with the service provider’s rules and restrictions regarding use of products, and/or Services. You agree that any violation of any such service provider’s rules and restrictions may result in cancellation of your purchase(s), in your being denied access to the applicable service(s), in your forfeiting any monies paid for such Services, and/or in our debiting your account for any costs we incur as a result of such violation.
                </Typography>

                <Typography variant="body1" paragraph>
                    ii) In order to access the Platform Services on the Platform and your account on the Platform (“Account”), You may have to register on the Platform by providing details including but not limited to mobile number, mother’s name, father’s name, email address, password, date of birth, gender, Permanent Account Number (PAN), Aadhaar, signature, marital status, nominee details, cancelled cheque, photograph and video recording, etc. and other information as may be required by the Firm from time to time. Your Account will be activated once Firm receives the requisite details.
                </Typography>

                <Typography variant="body1" paragraph>
                    iii) You hereby give your consent to Firm, its group entities, associates and affiliates as applicable to share your details on need to know basis.
                </Typography>

                <Typography variant="body1" paragraph>
                    iv) We may also ask you for certain financial information, including your billing address, bank account details, and/or other payment related details or other payment method data, and debit instructions or other standing instructions to process payments for the Platform Services. We may ask you to provide certain additional information about yourself on a case to case basis. You shall ensure and confirm that the Account information and requisite documents provided by you is complete, accurate and up-to-date. If there is any change in the Account information, you shall promptly update Your Account information and requisite documents on the Platform. If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or if the Firm has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, the Firm has the right to suspend or terminate your Account and refuse any and all current or future use of the Platform (or any portion thereof) at its discretion, in addition to any right that the Firm may have against You at law or in equity, for any misrepresentation of information provided by You.
                </Typography>

                <Typography variant="body1" paragraph>
                    v) You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under Your Account and also agree to keep your login credentials safe and confidential at all times. You further agree to promptly change your login credentials and inform the Firm immediately in case of any actual or suspected unauthorized use of Your Account. The Firm cannot and will not be liable for any loss or damage arising from your failure to comply with this provision. You may be held liable for losses incurred by the Firm or any other user of or visitor to the Platform due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account information secure and confidential.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    5. Service Description
                </Typography>

                <Typography variant="body1" paragraph>
                    LegalSages, inter alia, provides users with access to information focused on legal and social welfare service (the “Service”). It is the user’s responsibility to obtain access to the platform, and that access may involve third-party fees (such as fees/subscription amounts and/or airtime charges, etc.). In addition, you must provide and are responsible for all equipment necessary to access the platform.
                </Typography>

                <Typography variant="body1" paragraph>
                    The usage of the platform, and furnishing of one’s personal/contact details, hereby qualifies as the user being interested in availing and purchasing the Service(s) that they have selected. The user hereby gives consent to being sent the policy PDF on their email ID by LegalSages/group entities/Firm/affiliates. The user hereby agrees that LegalSages may contact them either electronically or through phone, or permitted mode to understand their interest in the selected products and Service(s) (like renewals, reminders, etc.) and to fulfill their demand.
                </Typography>

                <Typography variant="body1" paragraph>
                    APEX LEGAL has the right to share your details with its affiliates and partners, and you may be contacted by the affiliates and partners for information and services through email, telephone and/or SMS, social media, or permitted modes. It is reiterated that LegalSages is acting as a platform and offering the services.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    6. Users Covenants
                </Typography>

                <Typography variant="body1" paragraph>
                    a) You agree and confirm to avail and receive promotional materials and/or special offers from LegalSages through email or SMS and/or Socials and/or permitted mode.
                </Typography>

                <Typography variant="body1" paragraph>
                    b) The User hereby agrees, confirms, and acknowledges that not all the Services on the Platform are provided by APEX LEGAL and services may be provided by the Associate, Group entities, Affiliates, or other third parties.
                </Typography>

                <Typography variant="body1" paragraph>
                    c) When you access the platform/service, the Firm will act on your express instructions, and any transactions carried out on your behalf shall be subject to your sole consent, discretion, and responsibility.
                </Typography>

                <Typography variant="body1" paragraph>
                    d) For providing the Services, we shall partner with professionals such as CA, CS, Advocates, etc.
                </Typography>

                <Typography variant="body1" paragraph>
                    e) To offer the Services to you, you may be required to create a user profile on the Platform and provide personal and financial information, including but not limited to name, email address, gender, date of birth, contact information, bank details and documents, PAN, and other information including User Data. You acknowledge that providing such information is necessary for the use of the Services and agree that such information shall be accurate and complete, failing which the Firm may cancel or refuse any part of the Services offered to you.
                </Typography>

                <Typography variant="body1" paragraph>
                    f) You hereby grant your express authorization to LegalSages to access, collect, store, and use such data for providing the Services. We are required to collect User Data, in accordance with the Firm’s Privacy Policy.
                </Typography>

                <Typography variant="body1" paragraph>
                    g) You hereby acknowledge, agree, and grant your express authorization to LegalSages that the information shared by you on the platform or received by the Firm and Group/subsidiary companies from various intermediaries and third parties may be shared within the Firm, Associate/Group entities, and other third parties.
                </Typography>

                <Typography variant="body1" paragraph>
                    h) You hereby acknowledge, agree, and grant your express authorization to the Firm and associate/group entities to receive your personal and other data from different third parties and intermediaries to provide services on the platform.
                </Typography>

                <Typography variant="body1" paragraph>
                    i) You declare that you have read, understood, and agree to the disclosures made here.
                </Typography>

                <Typography variant="body1" paragraph>
                    j) You agree that the records maintained by the Firm in respect of the transactions effected using our Services shall be sufficient proof of such transactions.
                </Typography>

                <Typography variant="body1" paragraph>
                    k) The Firm/Associate/Group entities shall not be liable or responsible for any actual or threatened losses, damages, expenses, disputes, or otherwise any claims made in respect of the use or misuse of the Services by you.
                </Typography>

                <Typography variant="body1" paragraph>
                    l) The Firm/Associate/Group entities shall be entitled to cancel or refuse to offer the Services to you without any notice or obligation to provide reasons.
                </Typography>

                <Typography variant="body1" paragraph>
                    m) The efficiency and confidentiality of the information provided by you shall always be subject to the software and hardware used by you, including the capability of your electronic device, internet connection, and such other specifications or features that may be relevant for or ancillary to accessing the Platform and availing the Services.
                </Typography>

                <Typography variant="body1" paragraph>
                    n) By accessing the Services through the Platform, you represent that (a) you are competent to contract with the Firm and (b) you consent to these Terms.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    7. Eligibility to Use
                </Typography>

                <Typography variant="body1" paragraph>
                    a) By accepting the Terms, You hereby represent that:
                </Typography>

                <Typography variant="body1" paragraph>
                    i. You are of 18 (eighteen) years of age or older and in case you are acting as guardian on behalf of a minor, you have the necessary authority to register/sign up on the Platform for availing the Services on behalf of the minor;
                </Typography>

                <Typography variant="body1" paragraph>
                    ii. You are of legal age to form a binding contract, are not a person barred from receiving the Platform Services under the applicable laws, and are competent to enter into a binding contract. The Firm reserves the right to refuse access to the Platform to new Users and any such user who has been suspended, disqualified, debarred, and/or removed by the Firm for any regulatory and/or statutory authority reason whatsoever shall not be entitled to avail the Platform Services;
                </Typography>

                <Typography variant="body1" paragraph>
                    iii. You agree to abide by the Terms, offer documents, privacy policy, disclosure documents, and any other information provided by you on and through the Platform for the provision of the Platform Services;
                </Typography>

                <Typography variant="body1" paragraph>
                    iv. You shall be solely responsible for all the decisions executed by you on the Platform in your selected services, and the Firm shall not be responsible for any loss or harm incurred by you due to such activities carried out by you.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    8. Violation of the Terms
                </Typography>

                <Typography variant="body1" paragraph>
                    You agree that any violation by you of these Terms will constitute an unlawful and unfair business practice, and will cause irreparable harm to the Firm and/or its group entities and affiliates, as the case may be, for which monetary damages would be inadequate. You consent to the Firm obtaining any injunctive or equitable relief that they deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies that the Firm may have at law or in equity. If the Firm takes any legal action against you as a result of your violation of these Terms, they will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief that may be granted.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    9. Users Acknowledgment
                </Typography>

                <Typography variant="body1" paragraph>
                    i) Users hereby confirm, agree and unconditionally acknowledge:
                </Typography>

                <Typography variant="body1" paragraph>
                    a) To pay Firm all applicable transaction charges, commissions, fees, GST, and other taxes or levies as notified by Firm from time to time in writing.
                </Typography>

                <Typography variant="body1" paragraph>
                    b) That there may be situations, such as a breach of applicable laws or the Terms, where Firm needs to suspend or terminate services, which may restrict the Client’s access to certain or all features on the Platform. Once services are suspended or terminated, regardless of the reason, the Client’s right to use the services immediately ends. Firm will not be liable for any claims or damages arising from the suspension or termination of services or related actions. Firm's right to suspend or terminate the Client’s account is in addition to any remedies or obligations prescribed by law. If the Client is dissatisfied with any action taken, they can file a grievance with Firm supported by relevant documentation, which will be addressed according to applicable law. Firm's decision on reinstating services will be final.
                </Typography>

                <Typography variant="body1" paragraph>
                    c) Clients represent and warrant to Firm that they are legally capable of entering into these Terms, and are not barred by law from accessing the Platform or using the services provided through the platform. These Terms do not violate any agreements or rights of other parties with whom the Client has prior agreements. The Client will provide valid and updated personal or requisite details, including required documentary proof such as PAN and any identification required by regulatory authorities, if any. The Client confirms that all such details are accurate and truthful.
                </Typography>

                <Typography variant="body1" paragraph>
                    d) Firm shall not be liable for any delays or defaults in the performance of its obligations due to contingencies beyond its control. Such contingencies include, but are not limited to, losses caused directly or indirectly by force majeure events such as fires, floods, civil commotions or riots, earthquakes, cyclones, tsunamis, wars, strikes, pandemics or health calamities, terrorist actions, system failures, breakdowns in internet links, or any regulatory actions/changes by the government.
                </Typography>

                <Typography variant="body1" paragraph>
                    e) Firm does not guarantee uninterrupted or error-free services on its Platform, and all services are provided "as is" and "as available," without any warranties. Clients acknowledge that online services involve uncertainties and complexities with hardware, software, networking, and communication systems, which may lead to interruptions or unavailability of services.
                </Typography>

                <Typography variant="body1" paragraph>
                    f) Firm is not responsible for any disruptions in service. Clients authorize Firm to take necessary steps on their behalf for transaction completion, but Firm is not obligated to do so. Firm reserves the right to contact clients via phone and SMS, even if they are registered under DND. Failure to exercise any rights does not waive any further rights, and any conflict between policies will be resolved in a specified order of prevalence. Firm and its affiliates are not liable for indirect, consequential, or punitive damages, and their total liability will not exceed INR 50.
                </Typography>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    10. Disclaimer of Liability and Warranty
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>i) Accuracy of Information and Errors</strong>
                </Typography>
                <Typography variant="body2">
                    There is a possibility of inaccurate information or errors, including pricing errors, on the content, products, and services published on the platform. We do not guarantee absolute accuracy and disclaim all liability for any errors or other inaccuracies. We reserve the right to correct any pricing errors on the platform/site, including pending reservations made at incorrect prices. APEX LEGAL makes no representation regarding the suitability of the information, platform, products, and services. All information, software, products, and services are provided "AS IS," without any warranties. We disclaim all warranties regarding the platform, its services, and any communications, including the absence of harmful components. Service providers on this platform are independent affiliates, and APEX LEGAL is not liable for their actions, errors, omissions, or negligence.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>ii) No Warranty on Platform or Services</strong>
                </Typography>
                <Typography variant="body2">
                    To the maximum extent permitted by law, the platform and its content are provided "as is" without warranties of any kind, including implied warranties of merchantability or fitness for a particular purpose. We do not guarantee that (a) the platform or platform services will meet your requirements, (b) your use of the platform will be uninterrupted, secure, or error-free, (c) the results from the use of the platform will be effective or reliable, (d) any defects in the platform will be corrected. No advice or information obtained from APEX LEGAL or its affiliates shall create any warranty not expressly stated in the terms.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>iii) Liability for User Content</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL will have no liability related to any user content arising under intellectual property rights, libel, privacy, or other laws. APEX LEGAL disclaims liability for the misuse, loss, modification, or unavailability of any user content. We are not liable for unauthorized use of your account or account information on the platform.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>iv) Platform Accuracy and Availability</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL has made reasonable efforts to ensure that the platform's information is correct. However, we do not warrant or make representations regarding the quality, accuracy, or completeness of data, services, or platform functionalities. We are not responsible for the inability to use the platform due to delays, maintenance, or technical issues.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>v) Software and Hardware Ownership</strong>
                </Typography>
                <Typography variant="body2">
                    You acknowledge that the software and hardware underlying the platform are the property of the respective vendors. The permission to access the platform does not transfer ownership rights of such software/hardware to you.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>vi) Availability of Platform Services</strong>
                </Typography>
                <Typography variant="body2">
                    Not all platform services are available in all geographical areas. APEX LEGAL reserves the right to determine the availability and eligibility for any platform services.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>vii) Third-party Links</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL is not responsible for the availability of third-party services linked from the platform. We recommend that you review the terms of use of third-party sites before accessing them. APEX LEGAL disclaims all warranties regarding third-party products and services.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>viii) Force Majeure Events</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL is not liable for failure to perform obligations under these terms if prevented by a force majeure event (e.g., natural disasters, wars, strikes, technical failures). The obligations will be suspended for the duration of the event.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>ix) Limitation of Liability</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL and its affiliates are not liable for direct, indirect, or consequential damages arising from your use of the platform. In no event will liability exceed the greater of (a) the service fees you paid, or (b) INR 100.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>x) Indemnification</strong>
                </Typography>
                <Typography variant="body2">
                    You agree to indemnify and hold APEX LEGAL harmless from any claims or damages arising from your breach of these terms or misuse of the platform.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>xi) Accuracy of Information</strong>
                </Typography>
                <Typography variant="body2">
                    You warrant that all details provided to APEX LEGAL are accurate and do not violate third-party rights.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>xii) Subscription to Platform Services</strong>
                </Typography>
                <Typography variant="body2">
                    By subscribing to platform services, you acknowledge that your information may be visible to other users on the platform.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    <strong>xiii) Limitation of Liability (Extended)</strong>
                </Typography>
                <Typography variant="body2">
                    APEX LEGAL and its affiliates are not liable for any damages resulting from your use of the platform. If APEX LEGAL is found liable, the maximum liability will be limited to the amount you paid for the service, or INR 100, whichever is greater.
                </Typography>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    11. Refund Policy
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    The transactions on the Platform with respect to the Platform Services for availing/subscription of Services are non-refundable. You hereby agree and acknowledge that once the subscription charges/fees are paid for the Services, the same cannot be refunded to you. The Firm does not owe any liability to refund the subscription charges/fees if you decide to discontinue availing services, terminate the Account, or under any other circumstances whatsoever. The User does not have any right to claim a refund in any condition whatsoever. Subscription charges/fees once paid shall not be refunded.
                </Typography>

                {/* Report Abuse Section */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 4 }}>
                    12. Report Abuse
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    In the event you come across any abuse or violation of these Terms or if you become aware of any objectionable content on the Platform, please report the same to the following e-mail id:
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1, color: 'blue' }}>
                    hello@legalsages.com
                </Typography>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    13. Communications:
                </Typography>

                {/* Subsection a */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    a) You hereby expressly agree to receive communication (including transactional messages) or by way of SMS and/or E-mail or through WhatsApp or other permitted mode from the Firm or LegalSages or Group entities or affiliates or any third party in connection with the Platform Services or Your registration on the Platform. You can unsubscribe/opt-out from receiving communications through SMS and e-mail anytime by sending an email at
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1, color: 'blue' }}>
                    hello@legalsages.com
                </Typography>

                {/* Subsection b */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    b) The Firm reserves a right to create any social platform groups (“Groups”) through its representatives (hereinafter referred to as “Group Admin”) who are expressly authorized to control, monitor, and administer the Groups on its behalf. The usage, membership, and participation in the Group are subject to the following terms and conditions:
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    You shall not:
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    i. use the Groups for circulating, uploading, transmitting, or otherwise making available any information, data, or content that is unlawful, harassing, threatening, harmful, tortuous, defamatory, libellous, abusive, violent, obscene, vulgar, invasive of another’s privacy, hateful, racially or ethnically offensive, or otherwise objectionable;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    ii. stalk, harass, threaten or harm another user using the Groups;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    iii. engage in any copyright infringement or other intellectual property infringement, or disclose any trade secret or confidential or personal information in violation of a confidentiality, employment, or nondisclosure agreement and/or privacy norm to which you are subject to;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    iv. post, send, transmit or otherwise make available any unsolicited or unauthorized e-mail messages, advertising, promotional materials, junk mail, spam, or chain letters, including, without limitation, bulk commercial advertising, and informational announcements;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    v. upload, post, e-mail, transmit, store or otherwise make available any material that contains viruses or any other computer code, files or programs designed to harm, interfere or limit the normal operation of the Service (or any part thereof), or any other computer software or hardware;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    vi. interfere with or disrupt the Group services;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    vii. plan or engage in any illegal activity using the Groups;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    viii. circulate or post any jokes and poems;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    ix. circulate or post any religious or politically oriented views or comments;
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    x. pretend to be anyone, or any entity, You are not i.e. You may not impersonate or misrepresent yourself as another person (including celebrities), entity, another participant of the Groups, an employee or official or representative of Firm;
                </Typography>

                {/* Subsection c */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    c) The Group Admin reserves the right at all times to determine, in its sole discretion, whether the content posted on the Group is appropriate and in compliance with these Terms. The Group Admin further reserves the rights to remove any participant/member from the Group if it finds you or any participant/member to have circulated/posted any content which is objectionable or improper for the Groups or its participants and/or deemed to have violated the Terms or for any reason whatsoever. The participants shall not challenge or question such removal by the Group Admin or declaration of any content as inappropriate or not compliant with the Terms. The participant hereby agrees and acknowledges that any of such actions by the Groups Admin shall not be construed as defamatory by the participant or you. This Group shall be used only for communication and group chat in relation to financial investments, and financial products.
                </Typography>

                {/* Subsection d */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    d) You agree and acknowledge that Your personal information including your mobile number disclosed in the Groups may be used by the participants and/or the Groups Admin/the Firm to communicate or establish contact with you or for any other purposes. You hereby further unconditionally consent that such communications via SMS/text messaging services and/or voice call or permitted mode by any other participant, Group Admin/ Firm is (a) upon the request and/or authorization by you, (b) ‘transactional’ and not an ‘unsolicited commercial communication’ under applicable laws including under the guidelines issued by Telecom Regulation Authority of India (“TRAI”) and (c) in compliance with the relevant guidelines of TRAI or such other authority in India and abroad. You shall indemnify the Group Admin and/or Firm against all types of losses and damages incurred by it or its affiliates, group entities, founder, officers, employees, agents, partners and licensors due to any action taken by TRAI, access providers (as per TRAI regulations) or any other authority due to any erroneous complaint raised by you or anyone on the Group Admin and/or Firm or its affiliates, group entities, founder, officers, employees, agents, partners and licensors with respect to the intimations mentioned above or due to a wrong number or other contact details provided by you for any reason whatsoever.
                </Typography>
                {/* Section 14: Posts */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    14. Posts
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    The Firm may provide and display content on the Platform which features specific articles/write ups by third parties in relation to the services. You hereby agree and acknowledge that such content displayed on the Platform does not represent the views and/or recommendations of the Firm and/or LegalSages, and You are required to read the documents carefully before taking action/decision.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    You may also be allowed to post and comment on such content on the Platform and you hereby undertake to ensure that such comments shall not be offensive and will be in accordance with applicable laws. All material added, created, submitted, or posted to the Platform by you is your sole responsibility. The Firm reserves the right to review any information provided/data uploaded, if any, by you on the Platform and delete any information/data that is inconsistent with these Terms.
                </Typography>

                {/* Section 15: Online Availability, Impressions & Disclaimer */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    15. Online Availability, Impressions & Disclaimer
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    In addition to the other disclaimers and limitations discussed in this notice, there are no guarantees or warranties regarding online availability, impressions, and click-through of platform/site, and any material, information, links, or content presented on the platform, may be unavailable for online access at anytime.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Advertising sponsors and advertising, if any and if permitted, must be approved by firm and/or Group Entities as applicable before the posting of any advertising material, information, links, content, banners, and graphics on platform. APEX LEGAL reserves the right to accept or reject any advertising sponsor or any advertising for any reason.
                </Typography>

                {/* Section 16: License Disclaimer */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    16. License Disclaimer
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    Nothing on platform shall be construed as conferring any license under any of APEX LEGAL or any third party’s intellectual property rights, whether by estoppel, implication, or otherwise.
                </Typography>

                {/* Section 17: Local Governing Laws */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    17. Local Governing Laws
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    APEX LEGAL controls and operates this platform from its headquarters in Delhi, India and makes no representation that the materials on the platform are appropriate or available for use outside India. If you use this platform/site from other locations, you are responsible for compliance with applicable local laws including but not limited to the export and import regulations of other countries.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Unless otherwise explicitly stated, all marketing or promotional materials found on this platform are solely directed to individuals, companies or other entities located in India and comply with the laws prevailing for the time being in force in India. Disputes, if any, shall be subject to arbitration proceedings. APEX LEGAL shall appoint the sole arbitrator. The venue and seat of the arbitration proceedings shall be at Delhi. The language of arbitration proceedings shall be English.
                </Typography>

                {/* Section 18: Links */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    18. Links
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    The Platform/Site or third parties may provide links to other World Wide Web sites or resources. Because APEX LEGAL has no control over such sites and resources, you acknowledge and agree that APEX LEGAL is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources.
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    You further acknowledge and agree that APEX LEGAL shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
                </Typography>
                {/* Section 19: Intellectual Property Right */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    19. Intellectual Property Right
                </Typography>

                {/* Subsection i */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    i. The platform is a property of APEX LEGAL. The trademarks, logos, tag lines, slogans, icons, service marks, and designs are the property of APEX LEGAL. This platform/site also uses the trade name, trademarks, logos, etc. of its business partners and associates. These business partners and associates have their respective rights on their trade name, trademarks, logos, etc. The graphics, texts, images, style, and designs are subject to intellectual property rights. Any kind of inappropriate use, including but not limited to the reproduction, distribution, display, or transmission of any content on this site is strictly prohibited unless specifically authorized in writing by the firm.
                </Typography>

                {/* Subsection ii */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    ii. The Platform and all information, content, materials, products including, but not limited to text, content, photographs, graphics, texts, video and audio content, and computer code (“Content”) on the Platform is owned and controlled by the Firm and/or group entities as applicable and the design, structure, selection, and feel and arrangement of the Content is protected by copyright, patent and trademark laws and other various intellectual property rights either in favor of the Firm, or third parties from whom the appropriate permissions have been taken under applicable laws. The trademarks, logos, and service marks displayed on the Platform (“Marks”) are the property of the Firm or other respective third parties, as the case may be.
                </Typography>

                {/* Subsection iii */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    iii. You are not permitted to use the Marks without the express prior written consent of the Firm or the third party that owns the Marks.
                </Typography>

                {/* Subsection iv */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    iv. Further, You understand and accept that all information, except Your personal information and other data submitted by You for the purposes of transacting on the Platform, through the Platform shall be deemed the property of the Firm, and the Firm and/or Group entities shall be free to use any ideas, concepts, know-how or techniques provided by You on the Platform, in any manner whatsoever. On initiating a contact or query through the Platform, You agree to be contacted by the Firm or group entities or any other entities with whom the Firm has entered into an arrangement for the provisions of Services to You.
                </Typography>

                {/* Section 20: Payment */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    20. Payment
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    We strongly recommend making subscription / fees payment through LegalSages “platform” and in case of failure of payment through platform; payment can be made through permitted mode as notified by LegalSages/Firm. We neither ask to make payment in an individual bank account nor in any third party bank account.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    For the purpose of collecting online payments, the platform is integrated with multiple payment gateways based on the requirements of business verticals at the platform. In case of failure in payments, we shall not be held responsible for such failure. LegalSages/firm shall reasonably help the User(s). However, in such a case, the liability will not be applicable on us or any of our affiliates/group entities.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    We do not make any representations regarding any other websites listed on the Platform which may be accessed through the Platform, and accordingly shall not be responsible for other contents or use of such websites/platforms. Kindly be aware, take due precaution and don’t make any payment, if someone directly and/or on behalf of LegalSages/firm ask and approach you to make any payment w.r.t service provided/to be provided on behalf of LegalSages/firm, in his/her/their individual and/or third party bank account.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    LegalSages/firm never ask for such payment and will not be held liable for any claim/losses (known/unknown) occurred on account of such incidence(s).
                </Typography>
                {/* Section 21: Our Social Handle */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    21. Our Social Handle
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    Facebook – <Link href="https://www.facebook.com/LegalSages" target="_blank">https://www.facebook.com/LegalSages</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    LinkedIn – <Link href="https://www.linkedin.com/Firm/LegalSages" target="_blank">https://www.linkedin.com/Firm/LegalSages</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Instagram – <Link href="https://www.instagram.com/LegalSages" target="_blank">https://www.instagram.com/LegalSages</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Twitter – <Link href="https://twitter.com/LegalSages" target="_blank">https://twitter.com/LegalSages</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    YouTube – <Link href="https://www.youtube.com/@LegalSages" target="_blank">https://www.youtube.com/@LegalSages</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    Telegram – <Link href="https://t.me/LegalSagesOfficial" target="_blank">https://t.me/LegalSagesOfficial</Link>
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    LegalSages – WhatsApp (having green tick)
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    We strongly recommend using the aforesaid social handles in order to interact and avail our services. In case of any update, we shall notify the same through permitted and authentic mode.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    There is an apprehension that someone with mala-fide intent may reach and offer you unauthorized services in our name through other than aforesaid social handles, LegalSages/firm disclaim all such offers and representation(s), and request you to take due precaution and report us about such incidence(s) in order to initiate an appropriate proceeding(s) against such person(s).
                </Typography>

                {/* Section 22: Cash Back */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    22. Cash Back
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    LegalSages/firm including its group entities is/are not involved in games, leagues, schemes, competitions etc which may involve distribution of prize monies, medals, gifts, etc. The payment gateway(s) integrated with platform may offer and facilitate the cash back for transaction(s) carried out using their respective payment gateway; LegalSages/firm is not involved in any cash-back process and cannot be held liable for the same in any manner.
                </Typography>

                {/* Section 23: Suspension and Termination */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', marginTop: 3 }}>
                    23. Suspension and Termination
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    i. The Terms will continue to apply until terminated by either you or the Firm as set forth below. If you object to the Terms or are dissatisfied with the Platform, your only recourse is to (i) close Your Account on the Platform; and/or (ii) stop accessing the Platform. In case you violate these Terms and/ or any applicable law, the Firm may, at any time and in its sole discretion, terminate Your Account and/or prevent you from accessing the Platform and/or Platform Services.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    ii. The Firm may delist you or block your future access to the Platform or suspend or terminate your Account if it believes, in its sole and absolute discretion that you have infringed, breached, violated, abused, or unethically manipulated or exploited any term of these Terms or anyway otherwise acted unethically. Notwithstanding anything in this clause, these Terms will survive indefinitely unless and until the Firm chooses to terminate them. If you or the Firm terminate your use of the Platform, the Firm may delete any Content or other materials relating to Your and the Firm shall have no liability to you or any third party for doing so. However, your transaction details may be preserved by the Firm for purposes of regulatory compliance.
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    iii. The Firm, in consultation with its group entities, associates, and affiliates may modify these terms at any time.
                </Typography>
                {/* Section 26: General Provisions */}
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    26. General Provisions
                </Typography>

                {/* Notice */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    i. <strong>Notice:</strong> All notices from the Firm will be served by email to your registered email address or by general notification on the Platform or by permitted mode. Any notice provided to the Firm pursuant to the Terms should be sent to
                    <Link href="mailto:hello@LegalSages.com" sx={{ marginLeft: 1 }}>hello@legalsages.com</Link>
                    with subject line – Attention:
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                    We may notify you about relevant information pertaining to your use of service/feature by push notification on the Platform or through any other means that we may deem appropriate. You authorize us to reach out to you through permitted/various modes.
                </Typography>

                {/* Assignment */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    ii. <strong>Assignment:</strong> You cannot assign or otherwise transfer the Terms, or any rights granted hereunder to any third Party. The Firm’s rights under the Terms are freely transferable by the Firm to any third party without the requirement of seeking your consent.
                </Typography>

                {/* Severability */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    iii. <strong>Severability:</strong> If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.
                </Typography>

                {/* Waiver */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    iv. <strong>Waiver:</strong> Any failure by the Firm to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by the Firm of that provision or right.
                </Typography>

                {/* IP Infringement */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    v. <strong>IP Infringement:</strong> If you believe the Platform violates your intellectual property, you must promptly notify the Firm in writing at
                    <Link href="mailto:hello@LegalSages.com" sx={{ marginLeft: 1 }}>hello@legalsages.com</Link>. These notifications should only be submitted by the owner of the intellectual property or an agent authorized to act on his/her behalf. However, any false claim by you may result in the termination of your access to the Platform. You are required to provide the following details in your notice:
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    a) The intellectual property that you believe is being infringed;
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    b) the item that you think is infringing and include sufficient information about where the material is located on the Platform;
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    c) a statement that you believe in good faith that the item you have identified as infringing is not authorized by the intellectual property owner, its agent, or the law to be used in connection with the Platform;
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    d) Your contact details, such as your address, telephone number, and/or email;
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    e) a statement that the information you provided in your notice is accurate, and that You are the intellectual property owner or an agent authorized to act on behalf of the intellectual property owner whose intellectual property is being infringed; and
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1, paddingLeft: 2 }}>
                    f) Your physical or electronic signature.
                </Typography>

                {/* Headings */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    vi. <strong>Headings</strong> are for reference purposes only and do not limit the scope or extent of such section.
                </Typography>

                {/* Governing Law */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    This Terms of Use and the relationship between you and LegalSages/Firm and/or its affiliates, founder, group entities, associates etc will be governed by the laws of India without regard to its conflict of law provisions. You and Firm agree to submit to sole arbitrator appointed by Firm with respect to any legal proceedings that may arise in connection with this Terms of Use. The failure of LegalSages/firm to act with respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches.
                </Typography>

                {/* Third-Party Beneficiaries */}
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    vii. Except as provided in this Terms of Use, there shall be no third-party beneficiaries to this Terms of Use. This Terms of Use constitutes the entire agreement between you and APEX LEGAL and governs your use of platform, superseding any prior agreements between you and APEX LEGAL with respect to platform. Please feel free to contact us at
                    <Link href="mailto:hello@LegalSages.com" sx={{ marginLeft: 1 }}>hello@legalsages.com</Link> regarding any questions on the Terms.
                </Typography>
            </Box>
        </Container>
        </>
    );
};

export default TermsConditions;
