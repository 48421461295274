import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Paper } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material'; // Import icons
import './style.scss'; // Import SCSS for styling

const Testimonials = () => {
  const testimonials = [
    {
      name: 'Amit Sharma',
      feedback: 'Apex Legal provided top-notch legal services, exceeding all expectations!',
      designation: 'CEO, Innovatech Solutions',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Rajiv Malhotra',
      feedback: 'The team’s professionalism and expertise are unmatched. Highly recommend!',
      designation: 'Founder, Startup Sphere',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Nayan Agarwal',
      feedback: 'They guided us seamlessly through complex legal procedures.',
      designation: 'Manager, Reliance FinServ',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Priya Iyer',
      feedback: 'Efficient, reliable, and highly skilled legal professionals.',
      designation: 'Director, GreenBuild Enterprises',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Ananya Das',
      feedback: 'Outstanding service! Apex Legal truly understands client needs.',
      designation: 'COO, EcoVision Industries',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Rohan Mehta',
      feedback: 'Their attention to detail and commitment is exceptional.',
      designation: 'Investor, NextWave Ventures',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
    {
      name: 'Ishita Kapoor',
      feedback: 'We couldn’t have asked for a better legal partner for our business.',
      designation: 'HR Head, TalentWorks Pvt Ltd.',
      image: 'https://www.citypng.com/public/uploads/preview/hd-man-user-illustration-icon-transparent-png-701751694974843ybexneueic.png',
    },
  ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <ArrowForward style={{ color: '#3498db', fontSize: '30px' }} />, // Custom icon for next
    prevArrow: <ArrowBack style={{ color: '#3498db', fontSize: '30px' }} />, // Custom icon for previous
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box className="testimonials-section">
      <Typography variant="h3" component="h2" className="testimonials-title">
        Our Clients Love Us
      </Typography>
      <Slider {...settings} className="testimonials-carousel">
        {testimonials.map((testimonial, index) => (
          <Paper key={index} className="testimonial-card">
            <div className="testimonial-header">
              <img
                src={testimonial.image}
                alt={`${testimonial.name}`}
                className="testimonial-image"
              />
              <Typography variant="h6" className="testimonial-name">
                {testimonial.name}
              </Typography>
            </div>
            <Typography className="testimonial-designation">
              {testimonial.designation}
            </Typography>
            <Typography className="testimonial-feedback">
              "{testimonial.feedback}"
            </Typography>
          </Paper>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonials;
