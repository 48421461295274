import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, Typography, Button, Modal, Backdrop, Fade, CircularProgress } from '@mui/material';
import Layout from './Layout/Layout';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ServicesPage from './pages/Service';
import ContactPage from './pages/Contact';
import BookPage from './pages/Book';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import NoPageFound from './pages/NoPageFound';

import DigitalScam from './components/DigitalScam/DigitalScam';
import SBRL from './components/SBRL/SBRL';
import Valuation from './components/Valuation/Valuation';
import VirtualGeneralCounsel from './components/VirtualGeneralCounsel/VirtualGeneralCounsel';
import Compliance from './pages/Compliance';
import FundRaising from './pages/FundRaising';

const ConsentModal = ({ open, onConsent }) => {
  return (
    <Modal
      open={open}
      onClose={() => { }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            borderWidth: 0,
            outline: 0,
          }}
        >
          <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
            Disclaimer
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }} style={{textAlign: "justify"}}>
            The content available on this website <strong>(www.legalsages.com)</strong> is provided for informational purposes only and should not be interpreted as solicitation, advertisement, or legal advice. The materials and information presented on this platform are not intended to establish or invite a lawyer-client relationship.
            While every effort has been made to ensure the accuracy and timeliness of the information provided, Apex Legal assumes no responsibility for any reliance placed by readers on the content herein. Apex Legal shall not be held liable for any loss or damage arising from inaccuracies, omissions, or the interpretation of the information provided. Readers are encouraged to seek independent and professional verification of any information prior to acting upon it.
            This website employs cookies to enhance user experience and improve functionality. By continuing to use the website without modifying your privacy settings, you consent to our use of cookies as outlined in our Terms of Use and Privacy Policy.
            <br />
            <br />
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => onConsent(true)}
            >
              I Consent
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => onConsent(false)}
            >
              I Decline
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

const App = () => {
  const [consented, setConsented] = useState(null);

  useEffect(() => {
    const savedConsent = localStorage.getItem('userConsent');
    if (savedConsent === 'true') {
      setConsented(true);
    } else {
      setConsented(false);
    }
  }, []);

  const handleConsent = (decision) => {
    if (decision) {
      localStorage.setItem('userConsent', 'true');
      setConsented(true);
    } else {
      window.location.href = 'https://www.google.com';
    }
  };

  if (consented === null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
      {!consented && <ConsentModal open={!consented} onConsent={handleConsent} />}
      {consented && (
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/book" element={<BookPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />

            <Route path="/digital-scam" element={<DigitalScam />} />
            <Route path="/business-setup" element={<SBRL />} />
            <Route path="/valuation" element={<Valuation />} />
            <Route path="/virtual-general-counsel" element={<VirtualGeneralCounsel />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/fund-raising" element={<FundRaising />} />

            <Route path="*" element={<NoPageFound />} />
          </Routes>
        </Layout>
      )}
    </Router>
  );
};

export default App;
