import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './style.scss';

const FundHero = () => {
    return (
        <Box className="services-hero">
            <Box className="hero-content">
                <Typography variant="h2" className="hero-heading" sx={{ color: "#ffffff" }}>
                    Empower Your Business with Strategic Funding Solutions
                </Typography>
                <Typography variant="h5" className="hero-subheading">
                    Secure the capital you need to fuel growth, innovation, and long-term success with our tailored funding strategies.
                </Typography>
            </Box>
        </Box>
    );
};

export default FundHero;
