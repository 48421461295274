import React from 'react';
import { Typography, Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NoPageFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <>
    <Helmet>
      <title>
        404 || Legal Sages || Navigating Legal Empowerment!
      </title>
    </Helmet>
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
      <Box sx={{ maxWidth: 600, padding: 3, border: '1px solid #ddd', borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h1" sx={{ fontSize: '120px', fontWeight: 'bold', color: 'error.main' }}>
          404
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Oops! The page you're looking for does not exist.
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          sx={{ padding: '10px 20px', fontSize: '16px' }} 
          onClick={handleGoHome}
        >
          Go Back to Homepage
        </Button>
      </Box>
    </Container>
    </>
  );
};

export default NoPageFound;
