import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import './style.scss';

const Clients = () => {
  const clients = [
    'https://play-lh.googleusercontent.com/XY-wCgzi3uSNHlNMJ-2dt3eHndV8EJbmQPdr2zIqvtwD0PV6hEy2-Vj1nOIsOEwT0pU',
    'https://media.licdn.com/dms/image/v2/C4D0BAQHee-fK_Gu7Sw/company-logo_200_200/company-logo_200_200/0/1630829070941?e=2147483647&v=beta&t=j267G1rMDXuWB00WkRvnMarJ5ZGosg0n0hIVRaLasxE',
    'https://pbs.twimg.com/profile_images/1621166045741588482/YOKxmJPt_400x400.png',
    'https://upload.wikimedia.org/wikipedia/commons/5/50/SIP-logo_eng_new-%28rgb%29.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Ministry_of_Corporate_Affairs_India.svg/1200px-Ministry_of_Corporate_Affairs_India.svg.png',
    'https://yt3.googleusercontent.com/ytc/AIdro_kcBW073FvrkAJ34oe4cihRdSKJqSK9WIsQ9kw_7ZMVOvE=s900-c-k-c0x00ffffff-no-rj',
    'https://m.economictimes.com/thumb/msid-55707084,width-1200,height-900,resizemode-4,imgsize-37644/amtek-auto-3-units-agree-to-equity-infusions-by-new-investors.jpg',
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeP-iT_EqpF6CFWPpyqPt-Ytzq1xZPI4i_Pg&s',
    'https://www.apostilleservice.co.in/wp-content/uploads/2018/10/Religare-logo-min.jpg',
    'https://th.bing.com/th/id/OIP.eBX0TuhyvesEwO6PfCFB-gAAAA?rs=1&pid=ImgDetMain',
    'https://www.indianinternship.com/wp-content/uploads/2010/09/rjcorp-logo11.jpg',
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box className="clients-section">
      <Typography variant="h4" component="h2" className="clients-title">
        Our Esteemed Clients
      </Typography>
      <Slider {...settings} className="clients-carousel">
        {clients.map((client, index) => (
          <Box key={index} className="client-item">
            <img src={client} alt={`Client ${index + 1}`} className="client-image" />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Clients;
