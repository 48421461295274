import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import './style.scss';

const ServicesHeroSection = () => {
  return (
    <Box className="services-hero">
      <Box className="hero-content">
        <Typography variant="h2" className="hero-heading" sx={{color: "#fffff"}}>
          Unlock the Power of Expert Legal Services
        </Typography>
        <Typography variant="h5" className="hero-subheading">
          Empowering you with tailored legal solutions to overcome challenges and navigate the ever-evolving legal landscape.
        </Typography>
        {/* <Typography variant="body1" className="hero-description">
          At Legal Sages, we believe in providing not just legal advice, but comprehensive legal strategies that are both actionable and sustainable. Whether you're dealing with business, personal, or corporate matters, our experienced team is equipped with the knowledge and expertise to offer solutions that meet your unique needs. From complex dispute resolutions to proactive risk management, our services are designed to deliver results that matter to you.
        </Typography> */}
        {/* <Button variant="contained" color="secondary" className="hero-button">
          Get Started
        </Button> */}
      </Box>
    </Box>
  );
};

export default ServicesHeroSection;
