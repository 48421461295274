import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const HelpSection = () => {
  return (
    <Box sx={{ backgroundColor: "#ffffff", py: 6, px: 4 }}>
      <Grid container alignItems="center">
        {/* Left Section: Image */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="https://media.istockphoto.com/id/1214505793/vector/can-i-help-you-hand-drawn-vector-illustration-lettering-support-print-coronavirus-infection.jpg?s=612x612&w=0&k=20&c=G_qbaa_0c9_piJa77Osk11D1TRfz0lzZpMavbIOtOWM="
            alt="Help Illustration"
            sx={{
              width: "100%",
              maxHeight: "400px",
              borderRadius: 2,
              objectFit: "cover",
            }}
          />
        </Grid>

        {/* Right Section: Content */}
        <Grid item xs={12} md={6}>
          <Box my={4}>
            <Typography variant="h5" gutterBottom color="primary">
              How We Can Help
            </Typography>
            <Typography variant="body1" paragraph>
              Our experts provide comprehensive support to tackle these scams, offering:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">Risk assessments to identify vulnerabilities.</Typography>
              </li>
              <li>
                <Typography variant="body2">Personalized solutions to safeguard your data and privacy.</Typography>
              </li>
              <li>
                <Typography variant="body2">Expert guidance on legal recourse and recovery strategies.</Typography>
              </li>
            </ul>
            <Typography variant="body1" paragraph>
              <strong>Protect your digital world now! Contact us for a consultation.</strong>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HelpSection;
